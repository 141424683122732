<template>
    <div id="content" class="product-block-management">
        <div class="bar-actions">
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="saveProductBlock" customClass="btn-action" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="showConfirmDeleteBlockModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container">
            <div class="form left-site">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>
                <div class="label-container">
                    <h2 class="label-form">{{ $t('supervise.label.table_headers.product') }}</h2>
                    <div class="user-answer select">
                        <v-autocomplete hide-details class="select" v-model="product" item-text="name" item-value="id" :items="Object.values(products)" dense :no-data-text="$t('supervise.filter.no_results')"></v-autocomplete>
                    </div>
                </div>
                
                <div class="label-container">
                    <h2 class="label-form">{{$t('product.block.field')}}</h2> 
                    <p class="description">{{$t('product.block.caducity_msg')}}</p> 
                    <div class="user-answer select">
                        <v-autocomplete hide-details class="select" v-model="field" item-text="name" item-value="value" :items="keyname" dense :no-data-text="$t('supervise.filter.no_results')"></v-autocomplete>
                    </div>
                    <div class="user-answer select" v-if="field != 1 && Object.values(ingredients).length > 0">
                        <v-autocomplete hide-details class="select" v-model="ingredient" item-text="name" item-value="keyname" :items="Object.values(ingredients)"  dense :no-data-text="$t('supervise.filter.no_results')"></v-autocomplete>
                    </div>
                </div>
                <div class="label-container">
                    <h2 class="label-form">{{$t('product.block.field_value')}}</h2>
                    <p class="description">{{$t('product.block.value_msg')}}</p>
                    <div class="block-values" v-for="(value, index) in blockValues">
                        <input class="date-input end" type="date" :value="value.value" @change="changeValues($event, value)" :class="{error: error.blockValues}"/>
                        <span class="delete-icon" v-if="value.value && index != 0" @click="deleteBlockValue(value.id)"></span>
                    </div>
                    <Button bType="add-more" :bCallback="addBlockValue" customClass="btn-action" bLabel="Añadir valor"></Button>
                </div>
                <div class="label-container">
                    <div class="switch">
                        <h2 class="label-form" v-html="status ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                        <div class="switch-click" @click="toggleStatus()">
                            <input class="switch-input-form" type="checkbox" v-model="status" disabled="true" />
                        </div>
                    </div>
                    <p class="description">{{ $t('product.block.toggle_msg') }}</p>
                </div>
            </div>
            <div class="form product-helper">
                <div class="label-container">
                    <h2 class="label-form">{{ $t('product.block.search_by_ingredients') }}</h2>
                    <div class="user-answer">
                        <SearchBar @searchInputChange="updateSearchValue"></SearchBar>
                    </div>
                    <div class="list-of-product" v-if="Object.values(productContainsIngredients).length>0">
                        <span v-for="product in Object.values(productContainsIngredients)">{{ product.name }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import SearchBar from '@/components/ui/SearchBar.vue'

export default {
    name: 'ProductBlockManagement',
    props: { id: undefined },
    components: {
        SearchBar
    },
    data() {
        return {
            keyname: [
                { name: this.$t('product.block.caducity'), value: 1 },
            ],
            field: 1,
            product: null,
            status: true,
            blockValues: [{
                id: this.id ? this.id : crypto.randomUUID(),
                value: null
            }],
            error:{},
            ingredient: null,
            moreOptions: false,
            modifiedValues: []
        }
    },
    computed: {
        products() {
            const labels = this.$store.getters['label/getLabels']()

            //Sort by name
            return Object.values(labels).sort((a, b) => a.name.localeCompare(b.name))
        },
        ingredients() {
            const ingredient = this.$store.getters['block/getIngredientsProduct']
            if(this.id){
                this.ingredient = ingredient.find((item) => item.keyname === this.configBlock.keyname)
            }
            return ingredient
        },
        productContainsIngredients() {
            return this.$store.getters['block/getProductsContainIngredient']
        },
        configBlock() {
            if(this.id){
                return this.$store.getters['block/blockList'](this.id)
            }
        }
    },
    methods: {
        showMoreOptions() {
            this.moreOptions = !this.moreOptions
        },
        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },
        validateFields(){
            this.error = {}
            if(!this.product){
                this.error.product = true
            }
            if(!this.field){
                this.error.field = true
            }
            
            const fieldValue = this.field.value ? this.field.value : this.field
            if(fieldValue != 1 && !this.ingredient){
                this.error.ingredient = true
            }
            if(this.blockValues.some((item) => !item.value)){
                this.error.blockValues = true
            }
            log(">>Validation", JSON.stringify(this.error))
            if(Object.values(this.error).length > 0){
                return Object.values(this.error).every((item) => !item)
            }
            return true
        },
        saveProductBlock(){
            if(!this.validateFields()){
                return
            }
            this.$overlay.loading()
            if(this.id){
                this.$store.dispatch('block/updateBlock',{
                    id: this.id,
                    product: this.product,
                    keyname: this.field.value ? this.field.value : this.field,
                    nameOfIngredient: this.ingredient && this.ingredient.keyname ? this.ingredient.keyname : this.ingredient,
                    blockValues: this.blockValues.map((item) => item.value),
                    status: this.status,
                    modifiedValues: JSON.stringify(this.modifiedValues)
                }).then(() => {
                    this.$overlay.hide()
                    this.$store.commit('block/setIngredientsProduct',[])
                    this.$emit('back')
                    this.error = {}
                })
            }else {
                this.$store.dispatch('block/createBlock',{
                    product: this.product,
                    keyname: this.field.value ? this.field.value : this.field,
                    nameOfIngredient: this.ingredient,
                    blockValues: this.blockValues.map((item) => item.value),
                    status: this.status
                }).then(() => {
                    this.$overlay.hide()
                    this.$store.commit('block/setIngredientsProduct',[])
                    this.$emit('back')
                    this.error = {}
                })
            }
        },
        showConfirmDeleteBlockModal() {
            var self = this
    
            const modalMessage = this.$t('product.block.popup_delete') + self.configBlock.name + "'?" //TODO: translate

            this.$popup.delete({ message: modalMessage, callSave: this.deleteBlock })
        },
        deleteBlock(){
            var self = this
            const snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.configBlock.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store.dispatch('block/deleteBlock', { id : self.id}).then(() => {
                self.$snackbar.open(snackbarData)
                self.$emit('back')
            }).catch((error) => {
                    console.error('There was an error dispatching the delete operation:', error)
                })
        },
        load(){
            if(this.id && this.configBlock){
                this.product = this.configBlock.product_id
                this.field = this.configBlock.keyname === "caducidad" ?  1  : 2 
                this.status = this.configBlock.status === "1" ? true : false
                if(this.configBlock.value){
                    this.blockValues[0].value = moment(this.configBlock.value, "DD/MM/YYYY").isValid() ? moment(this.configBlock.value, "DD/MM/YYYY").format('YYYY-MM-DD') : this.configBlock.value
                    if(this.configBlock.children && Object.values(this.blockValues).length <= Object.values(this.configBlock.children).length){
                        const childrenValues = Object.entries(this.configBlock.children).map(([key, value]) => {
                            return { id: key, value: value }
                        })
                        this.blockValues = [...this.blockValues, ...childrenValues]
                    }
                }
                if(this.configBlock.ingredient){
                    this.ingredient = this.ingredients.find((item) => item.keyname === this.configBlock.ingredient)
                }
            }else{
                this.product = null
                this.keyname = [
                    { name: this.$t('product.block.caducity'), value: 1 },
                ],
                this.$overlay.loading()
                this.$store.dispatch('label/loadLabels',{}).then(() => {
                    this.$overlay.hide()
                })
            }
        },
        toggleStatus() {
            this.status = !this.status
        },
        changeValues(event,value){
            this.blockValues = this.blockValues.map((item) => {
                if(item.id === value.id){
                    item.value = event.target.value
                    if(this.id){
                        //See if the id is in the modifiedValues array and is not add event
                        const index = this.modifiedValues.findIndex((itemMod) => itemMod.id === value.id && itemMod.event === "add")
                        if(index !== -1){
                            this.modifiedValues[index].value = event.target.value
                        }else{
                            this.modifiedValues.push({event: "update", id: value.id, value: event.target.value})
                        }
                    }
                }
                return item
            })
            
        },
        addBlockValue(){
            const hasAllValue = this.blockValues.every((item) => item.value)
            if(hasAllValue){
                const id = crypto.randomUUID()
                this.blockValues = [...this.blockValues, { id: id , value: null }]
                this.modifiedValues.push({event: "add", id: id, value: null})
                this.error.blockValues = false
            }else{
                this.error.blockValues = true
            }
        },
        deleteBlockValue(id){
            this.blockValues = this.blockValues.filter((item) => {
                if(item.id !== id){
                    return item
                }else{
                    if(this.id){
                        //See if the id is in the modifiedValues array and is not add event
                        const index = this.modifiedValues.findIndex((itemMod) => itemMod.id === item.id && itemMod.event === "add")

                        if(index !== -1){
                            this.modifiedValues = this.modifiedValues.filter((item) => item.id !== id)
                        }else{
                            const index = this.modifiedValues.findIndex((itemMod) => itemMod.id === item.id && itemMod.event === "update")
                            if(index !== -1){
                                this.modifiedValues[index].event = "delete"
                            }else{
                                this.modifiedValues.push({event: "delete", id: id, value: item.value})
                            }
                        }
                    }
                }
            })
            
        },
        updateSearchValue(value){
            if (!value || value.length < 3) {
                return
            }
            setTimeout(() => {
                this.$store.dispatch('block/loadProductsFromIngredient',{ name: value })
            }, 1000)
        }
    },
    created() {
        this.load()
    },
    mounted() {
        
    },
    watch: {
        product: {
            handler: function (val) {
                if(val){
                    this.$overlay.loading()
                    this.field = 1
                    this.$store.dispatch('block/loadIngredientsFromProduct',{ product_id:val }).then(() => {
                        this.keyname = Object.values(this.ingredients).length > 0 ? [{ name: this.$t('product.block.caducity'), value: 1 }, { name: this.$t('product.block.ingredient'), value: 2 }] : [{ name: this.$t('product.block.caducity'), value: 1 }]
                        this.$overlay.hide()
                    })
                }
            },
            deep: true
        },
        $route(to, from) {
            this.$store.dispatch('block/resetProductsContainIngredient')
            this.load()
        }
    },
    updated() {}
}
</script>

<style lang="scss">
#content.product-block-management {
    .form-container{
        display: grid;
        grid-template-columns: 1fr 1fr;

        .form{
            width: 80%;
            .label-container{
                @include display-flex();
                @include flex-direction(column);
                gap: 8px;
                .block-values{
                    @include display-flex();
                    @include flex-direction(row);
                    @include align-items(center);
                    gap: 8px;

                    .delete-icon{
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    background: url('/img/delete_cccccc.svg') no-repeat center;
                    background-size: contain;
                    cursor: pointer;
                    }
                    .date-input {
                        width: 30%;
                        &.error{
                            border-color: $color-error-500;
                        }
                    }
                }
                
            }

            &.product-helper{
                margin-top: 16px;
                background: $color-neutral-200;
                padding: 16px;
                border-radius: 6px;
                height: fit-content;
                

                .list-of-product{
                    @include display-flex();
                    @include flex-direction(column);
                    gap: 8px;
                    margin-top: 16px;
                    max-height: 400px;
                    padding-left:8px;
                    overflow-y: auto;
                    width: 450px;
                    overflow-x: hidden;
                    span{
                        width: 100%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: pre;
                        padding-left: 16px;
                        position: relative;
                        &::before{
                            content: '';
                            position: absolute;
                            width: 6px;
                            height: 6px;
                            background: $color-black;
                            border-radius: 50%;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    
                }

            }

            .select{
                width: 100%;
            }

        }
    }
    .left-container {
        padding-left: 0;
    }
}
</style>
