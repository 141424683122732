<template>
    <div class="filter-box" :class="{ 'empty-results': isDisabled }">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- TYPE NAME -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.name') }}</label>
                <input :class="{ filtered: currentFilters.name && Object.values(currentFilters.name).length > 0, changed: JSON.stringify(currentFilters.name) != JSON.stringify(activeFilters.name) }" type="text" hide-details class="input" v-model="currentFilters.name" @keyup.enter="applyFilters()" />
            </div>
            
            <!-- TYPE STATUS -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.state') }}</label>
                <v-autocomplete :class="{ filtered: currentFilters.status && Object.values(currentFilters.status).length > 0, changed: JSON.stringify(currentFilters.status) != JSON.stringify(activeFilters.status) }" hide-details class="select" v-model="currentFilters.status" :items="Object.values(optionsStatus)" :placeholder="$t('assets.filter.filter_by')" item-text="name" item-value="id" multiple dense :no-data-text="$t('supervise.filter.no_results')" return-object>
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.status" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- Date -->
            <div class="filter date">
                <div class="filter-ball"></div>
                <label>{{ $t('analytics.date') }}</label>
                <v-select
                    :class="{ filtered: currentFilters.type_date, changed: currentFilters.type_date && currentFilters.type_date != activeFilters.type_date }"
                    hide-details
                    class="select"
                    v-model="currentFilters.type_date"
                    :items="optionsDate"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    @change="currentFilters.type_date == 'custom' ? (currentFilters.date = {}) : true"
                ></v-select>

                <div class="custom" v-if="currentFilters.type_date == 'custom'">
                    <div class="description">{{ $t('assets.filter.from') }}:</div>
                    <input class="date-input start" v-model="currentFilters.date.start_date" :max="minDate" type="date" @change="calcDate()" />

                    <div class="separator"></div>

                    <div class="description">{{ $t('assets.filter.to') }}:</div>
                    <input class="date-input end" v-model="currentFilters.date.end_date" :min="maxDate" type="date" @change="calcDate()" />
                </div>
            </div>
        </div>
        

        <FilterActions :showClearFiltersButton="showClearFiltersButton" :clearFiltersCallback="resetFilters" :filterCallback="applyFilters" :changesDetected="changesDetected" />
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import FilterActions from '../../ui/FilterActions.vue'

export default {
    name: 'FilterProductBlock',
    components: {
        Tags,
        FilterActions
    },
    props: {
        isDisabled: { type: Boolean, default: false }
    },
    data() {
        return {
            currentFilters: {},
            emptyFilters: {},
            activeFilters: {},

            // Filter variables
            changesDetected: false,
        }
    },
    computed: {
        optionsStatus() {
            const status = this.$store.getters['block/getOptionFilterStatus']
            status.map((item) => {
                item.name = this.$t(item.name)
            })
            return status
        },
        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)
        },
        defaultFilters(){
            return this.$store.getters['block/getDefaultFilters']
            
        },
        optionsDate() {
            return [
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        },
    },
    methods: {
        applyFilters() {
            this.$overlay.loading()
            this.activeFilters = structuredClone(this.currentFilters)
            this.changesDetected = false

            const definitiveFilters = {}

            if(this.currentFilters.name){
                definitiveFilters.name = this.currentFilters.name
            }else{
                definitiveFilters.name = ''
            }

            if (this.currentFilters.type_date) {
                if (this.currentFilters.type_date != 'custom') {
                    definitiveFilters.start_date = this.currentFilters.type_date
                } else {
                    definitiveFilters.start_date = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD')
                    definitiveFilters.end_date = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD')
                }
            } else {
                definitiveFilters.start_date = ''
                definitiveFilters.end_date = ''
            }

            if (this.currentFilters.status) {
                definitiveFilters.status = this.currentFilters.status.map((item) => item.id)
            }

            this.changesDetected = false

            this.$store.dispatch('block/loadList', definitiveFilters).then(() => {
                this.$overlay.hide()
            })
        },
        resetFilters() {
            if(JSON.stringify(this.currentFilters) == JSON.stringify(this.emptyFilters)){
                return
            }

            if(JSON.stringify(this.currentFilters) == JSON.stringify(this.activeFilters)){
                this.currentFilters = { ...this.emptyFilters }
                this.activeFilters = { ...this.emptyFilters }
                this.changesDetected = false
                this.applyFilters()
            }else{
                this.currentFilters = { ...this.emptyFilters }
                this.changesDetected = false
            }
        }
    },
    created() {
        this.emptyFilters = structuredClone(this.defaultFilters)
        this.currentFilters = structuredClone(this.defaultFilters)
        this.activeFilters = structuredClone(this.defaultFilters)
    },
    mounted() {
    },
    watch: {
        currentFilters: {
            handler() {
                this.changesDetected = JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
// Busca en assets :^)
</style>
