<template>
    <div id="content" class="product">
        <template v-if="form">
            <Form :id="blockID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <div>
                    <Button bType="create" :bCallback="addBlock" bLabel="product.addButton"></Button>
                    <!-- <Button bType="more" :bCallback="showMoreOptions"></Button>
                    <div class="more-options" v-if="moreOptions">
                        <Button id="more-download-btn" bType="print" bLabel="nvgt.report.print_product_list"></Button>
                    </div> -->
                </div>
            </div>

            <div class="product-container supervise-container" :class="{ 'with-trial': trialEndDate }">
                <!-- sidebar filters -->
                <div
                    class="sidebar-filters"
                    :class="{ 'empty-results': noChanges && Object.values(items).length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }"
                >
                    <FilterProducts
                        @noChanges="noChanges = true"
                        @changes="noChanges = false"
                        :isDisabled="noChanges && Object.values(items).length === 0"
                        @resetPageDatatable="top(true)"
                    ></FilterProducts>
                </div>
                <!-- table -->
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table
                        v-if="items"
                        v-model="selected"
                        :headers="headers"
                        show-select
                        fixed-header
                        :items="Object.values(items)"
                        sort-by="name"
                        :sort-asc="true"
                        hide-default-footer
                        :page.sync="pagination.page"
                        :items-per-page="pagination.itemsPerPage"
                        @page-count="pageCount = $event"
                    >
                        <template #item.name="item">
                            <div @click="goToReport(item.item.id)" class="product">
                                <img :src="item.item.image" class="product-img">
                                <div class="product-block">
                                    <span class>{{ item.item.name }}</span>
                                    <span class="">{{ item.item.updateMsg }}</span>
                                </div>
                            </div>
                        </template>
                        <template #item.value="item">
                            <div @click="goToReport(item.item.id)">
                                <span>{{ item.item.value }}</span>
                            </div>
                        </template>
                        <template #item.ingredient="item">
                            <div class="field" @click="goToReport(item.item.id)">
                                <div class="tag" :class="{ingredient:item.item.ingredient}" v-html="item.item.ingredient ? $t('product.block.ingredient_info', {ingredient: item.item.ingredient}): $t('product.block.caducity_info')"></div>
                            </div>
                        </template>
                        <template #item.status="item">
                            <div class="status" @click="goToReport(item.item.id)">
                                <Table-statuses :text="item.item.status == '1' ? $t('sites.form.active') : item.item.status == '-2' ? $t('sites.form.inactive') : ''" :status="item.item.status == '1' ? 'active' : 'inactive'"> </Table-statuses>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && itemsLoaded && Object.values(items).length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.values(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination
                            v-model="pagination.page"
                            @input="top(false)"
                            color="#2c52c4"
                            :length="pageCount"
                            :total-visible="maxPageVisibles"
                        ></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && Object.values(items).length == 0">
                        <EmptyTable
                            @buttonAction="addProduct"
                            :buttonText="$t('product.addButton')"
                            :title="$t('empty_table.users_title')"
                            :description="$t('empty_table.products')"
                        ></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import i18n from '@/i18n'
import FilterProducts from '@/components/domain/block/filter'
import Form from '@/components/domain/block/form'
import TableStatuses from '../components/ui/TableStatuses.vue'
import Tags from '@/components/ui/Tags.vue'


export default {
    components: {
        FilterProducts,
        Form,
        TableStatuses,
        Tags
    },
    name: 'ProductBlock',
    data() {
        return {
            blockID: undefined,
            form: false,
            moreOptions: false,
            noChanges: false,
            filtersSidebarIsHidden: false,
            selected: [],
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('supervise.label.table_headers.product'),
                    align: 'start',
                    value: 'name',
                    width: '400px',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.checklists.table_headers.date'),
                    align: 'start',
                    value: 'value',
                    width: '120px',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.block.field'),
                    align: 'start',
                    value: 'ingredient',
                    width: '120px',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.status'),
                    value: 'status',
                    align: 'start',
                    width: '150px',
                    class: 'header-table'
                }
            ],
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        itemsLoaded() {
            return this.$store.getters['block/blockList']
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        items() {
            const blocks = this.$store.getters['block/blockList']()
            if(blocks.length <= 0 || Object.values(blocks).length <= 0) return []

            Object.keys(blocks).forEach((key) => {
                const employee = this.employees[blocks[key].employee_id] ? `${this.employees[blocks[key].employee_id].name} ${this.employees[blocks[key].employee_id].surname}`  : '??'
                
                if(moment(blocks[key].value).isValid()){
                    blocks[key].value = moment(blocks[key].value).format('DD/MM/YYYY')

                    if(blocks[key].children){
                        const childValue = Object.values(blocks[key].children).map((value) => {
                            return moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : value
                        }).join(', ')

                        if(childValue){
                            blocks[key].value = `${blocks[key].value}, ${childValue}`
                        }
                    }
                }

                if(moment(blocks[key].last_update).isValid()){
                    blocks[key].last_update = moment(blocks[key].last_update).format('D MMM YYYY, H:mm')
                }

                blocks[key].updateMsg = this.$t('product.block.modify_by', {employee: employee, date: blocks[key].last_update})
            })

            return blocks
        },
        employees() {
            return this.$store.getters['employee/getList']
        },
        pagination: {
            get() {
                return this.$store.getters['block/getPagination']
            },
            set(value) {
                this.$store.commit('block/setPagination', value)
            }
        }
    },
    methods: {
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('ProductsSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        addBlock() {
            this.form = true
            this.blockID = undefined
            this.$router.push({
                name: 'ProductBlockAdd'
            }).catch(() => {})
        },
        closeForm() {
            this.form = false
            this.productID = false
            this.$router.push({
                name: 'ProductBlock'
            }).catch(() => {})
            this.$overlay.hide()
        },
        goToReport(id) {
            this.form = true
            this.blockID = id
            this.$router.push({
                name: 'ProductBlockEdit',
                params: {
                    id: id
                }
            }).catch(() => {})
        },
        load(){
            this.$overlay.loading()
            this.$store.dispatch('employee/loadList',{visible:0}).then(() => {
                this.$store.dispatch('block/loadList',{}).then(() => {
                    this.$overlay.hide()
                    if (this.$route.params.id) {
                        this.goToReport(this.$route.params.id)
                    } else if (this.$route.name == 'ProductBlockAdd') {
                        this.form = true
                        this.blockID = undefined
                    }else {
                        this.form = false
                        this.blockID = false
                    }
                })
            })
        }
    },
    watch: {
        '$route': 'load'
    },
    created() {
        this.load()
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
#more-download-btn {
    position: absolute;
    z-index: 44;
    right: 24px;
    margin-top: 5px;
}
.product {
    .product-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        .data-table {
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }
                .images {
                    @include background($size: contain);
                    width: 40px;
                    height: 40px;
                    border-radius: 4px;
                    margin: 0 auto;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }
                .status {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }

                .field{
                    .tag{
                        @include font-size($size: xs);
                        font-family: $text;
                        padding: 4px 8px;
                        border-radius: 4px;
                        max-width: 150px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: fit-content;
                        
                        strong{
                            @include font-size($size: xs);
                            font-family: $text-bold;
                        }
                    }
                }
                .product{
                    @include display-flex();
                    @include align-items(center);
                    gap: 16px;
                    .product-img{
                        width: 30px;
                        height: 30px;
                        object-fit: contain;
                    }
                    .product-block{
                        @include display-flex();
                        @include flex-direction(column);

                        span{
                            @include font-size($size: sm);
                            font-family: $text-medium;

                            //segundo span
                            &:nth-child(2){
                                @include font-size($size: xs);
                                font-family: $text;
                            }
                        }
                    }
                }

                .locations {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
            }
        }
        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
